import React from 'react';

import OutOfService from '../components/shared/OutOfService'

/**
 * Have to return 404 explicitly as an index page, because Gatsby expects an index page
 * and won't automatically show the 404 page at all if it is not defined. Can't use
 * `pathPrefix` in the config to make it work since the site is using the route internationalization
 * plugin that would require too much configuration otherwise.
*/
const Index = () => <OutOfService />;

export default Index;
